<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :options.sync="options"
    :server-items-length="totalItems"
    :loading="loading"
    sort-by="name"
    must-sort
    class=""
    calculate-widths
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ $t('Relations') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="options.search"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
          class="shrink mr-5"
        ></v-text-field>
        <v-btn
            :to="{ name: 'RelationCreate' }"
            fab
            dark
            color="primary"
        >
            <v-icon
            dark
            >
            mdi-plus
            </v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        flat
        class="mt-3"
      >
        <v-select
        v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
        :items="users"
        item-text="fullName"
        item-value="id"
        v-model="options.accountManagerId"
        clearable
        :label="$t('Account Managers')"
        class="mt-3 pr-1 top-filters shrink"

        dense
        ></v-select>
        <v-select
        :items="relStatuses"
        item-text="name"
        item-value="value"
        v-model="options.relationStatus"
        clearable
        :label="$t('Relation Status')"
        class="mt-3 pr-1 top-filters shrink"

        dense
        >
          <template v-slot:item="{item}">
              {{$t(item.name)}}
          </template></v-select>
        <v-select
        :items="ClientTypes"
        item-text="name"
        item-value="id"
        v-model="options.clientTypeId"
        clearable
        :label="$t('Client Type')"
        class="mt-3 pr-1 top-filters shrink"

        dense
        ></v-select>
      </v-toolbar>
    </template >

    <template v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1" v-slot:item.accountManagerId="{ item }">

        {{ getUser(item.accountManagerId) }}

    </template>
    <template v-slot:item.relationStatus="{ item }">

        {{ getRelStatus(item.relationStatus) }}

    </template>
    <template v-slot:item.clientTypeId="{ item }">

        {{ getClientType(item.clientTypeId) }}

    </template>
    <template v-slot:item.actions="{item}" >
        <v-btn :to="{name: 'RelationTransitions', query: { id: item.id,name:item.name,relationTransitions:item.relationTransitions }}"  plain text icon class="mr-2">
          <v-hover v-slot="{ hover }">
            <v-icon :color="hover? 'green': 'grey'"
            >
            mdi-eye
            </v-icon>
          </v-hover>
        </v-btn>
        <v-btn :to="{name: 'RelationEdit', query: { id: item.id, page: options.page }}" plain text icon class="mr-2">
            <v-hover v-slot="{ hover }">
            <v-icon :color="hover? 'green': 'grey'"
            >
            mdi-pencil
            </v-icon>
          </v-hover>
        </v-btn>

        <v-btn :to="{name: 'RelationDelete', query: { id: item.id,name:item.name, page: options.page }}" plain text icon class="">
            <v-hover v-slot="{ hover }">
              <v-icon  :color="hover? 'primary': 'grey'"
              >
              mdi-delete
              </v-icon>
            </v-hover>
        </v-btn>
    </template>
    <template v-slot:no-data>
      {{ $t('No items found') }}
    </template>
  </v-data-table>
</template>

<script>
    export default {
        data(){
            return{
                search: '',
                listSrch: {},
                totalItems: 0,
                options: {},
                loading: true,
                users:[],
                ClientTypes:[],
                relStatuses:[],
                headers: [],
                items: []
            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchItems()
                },
                deep: true,
            }
        },
        mounted ()
        {
            this.fetchItems();
        },
        created: function()
        {
            if(this.$route.query.page > 0){
              this.options.page = parseInt(this.$route.query.page);
            }

            this.fetchUsers();
            this.fetchrelStatuses();
            this.fetchClientTypes();

            this.headers.push({ text: this.$t('Name'), align: 'start', value: 'name'},
                    { text: this.$t('Contact Person'), align: 'start', value: 'contactPerson'})

            if(this.$store.state.user.user.role == 0  || this.$store.state.user.user.role == 1){
              this.headers.push({ text: this.$t('Account Manager'), value: 'accountManagerId' })
            }

            this.headers.push(
              { text: this.$t('Relation Status'), value: 'relationStatus' },
              { text: this.$t('Client Type'), value: 'clientTypeId' },
              { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false }
            )

        },

        methods: {
            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:'';
            },

            getRelStatus(id){
              let filterData = this.relStatuses.filter(function(relStatus){
                return relStatus.value == id
              })
              return filterData[0]!=undefined?this.$t(filterData[0].name):'';
            },

            getClientType(id){
              let filterData = this.ClientTypes.filter(function(ClientType){
                return ClientType.id == id
              })
              return filterData[0]!=undefined?filterData[0].name:'';
            },

            fetchUsers()
            {
                let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=true';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=true';
                    this.axios.get(uri2).then((response) => {
                        this.users = response.data.filter(function(user){
                            return user.role == 1 || user.role == 2;
                        });
                    });
                });
            },

            fetchrelStatuses()
            {
                let uri2 = this.$urlPrefix + '/RelationStatus/GetAll';
                this.axios.get(uri2).then((response) => {
                    this.relStatuses = response.data;
                });
            },

            fetchClientTypes()
            {
                let uri = this.$urlPrefix + '/ClientType/CountAll';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/ClientType/GetList?start=0&count='+response.data;
                    this.axios.get(uri2).then((response) => {
                        this.ClientTypes = response.data;
                    });
                });
            },

            fetchItems()
            {
              this.$router.replace({ query: { page: this.options.page } }).catch(()=>{});

              let uri = this.$urlPrefix + '/Relation/CountAll';
              let uri2 = this.$urlPrefix + '/Relation/GetList?start='+ (this.options.page -1) * this.options.itemsPerPage+'&count='+this.options.itemsPerPage;

              if(this.options.search){
                uri2 += '&search='+this.options.search;
              }

              if(this.options.accountManagerId){
                uri2 += '&accountManagerId='+this.options.accountManagerId;
              }

              if(this.options.relationStatus){
                uri2 += '&relationStatus='+this.options.relationStatus;
              }

              if(this.options.clientTypeId){
                uri2 += '&clientTypeId='+this.options.clientTypeId;
              }
              if(this.options.sortBy){
                uri2 += '&sort='+this.options.sortBy[0];
              }
               if(this.options.sortDesc){
                uri2 += '&sortDescending='+this.options.sortDesc[0];
              }
              this.axios.get(uri).then((response) => {
                this.totalItems = response.data;
                this.axios.get(uri2).then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
              });
            },
        }
    }
</script>
<style lang="scss">
  .top-filters{
    width: 150px;
  }
</style>
