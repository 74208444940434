var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":"name","must-sort":"","calculate-widths":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Relations')))]),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('v-btn',{attrs:{"to":{ name: 'RelationCreate' },"fab":"","dark":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-toolbar',{staticClass:"mt-3",attrs:{"flat":""}},[(_vm.$store.state.user.user.role == 0  || _vm.$store.state.user.user.role == 1)?_c('v-select',{staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"items":_vm.users,"item-text":"fullName","item-value":"id","clearable":"","label":_vm.$t('Account Managers'),"dense":""},model:{value:(_vm.options.accountManagerId),callback:function ($$v) {_vm.$set(_vm.options, "accountManagerId", $$v)},expression:"options.accountManagerId"}}):_vm._e(),_c('v-select',{staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"items":_vm.relStatuses,"item-text":"name","item-value":"value","clearable":"","label":_vm.$t('Relation Status'),"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}]),model:{value:(_vm.options.relationStatus),callback:function ($$v) {_vm.$set(_vm.options, "relationStatus", $$v)},expression:"options.relationStatus"}}),_c('v-select',{staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"items":_vm.ClientTypes,"item-text":"name","item-value":"id","clearable":"","label":_vm.$t('Client Type'),"dense":""},model:{value:(_vm.options.clientTypeId),callback:function ($$v) {_vm.$set(_vm.options, "clientTypeId", $$v)},expression:"options.clientTypeId"}})],1)]},proxy:true},(_vm.$store.state.user.user.role == 0  || _vm.$store.state.user.user.role == 1)?{key:"item.accountManagerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUser(item.accountManagerId))+" ")]}}:null,{key:"item.relationStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRelStatus(item.relationStatus))+" ")]}},{key:"item.clientTypeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getClientType(item.clientTypeId))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'RelationTransitions', query: { id: item.id,name:item.name,relationTransitions:item.relationTransitions }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1),_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'RelationEdit', query: { id: item.id, page: _vm.options.page }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'RelationDelete', query: { id: item.id,name:item.name, page: _vm.options.page }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }